/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var hide_search_bar = function(e) {
          var container = $(".search-input");
          var toggle = $('.icon-search');
          console.log(e.target);
          if (toggle.is(e.target) || container.has(e.target).length > 0) {
            console.log('i win');
            return;
          }
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            console.log('im should be called');
            container.removeClass('open');
            $(document).off('mouseup', this);
          }
        };

        $(function(){
          $('.search-toggle').click(function(event) {
            console.log('two called');
            if ($('.search-input').hasClass('open') && $('.search-input').val().length > 0) {
              $('#searchform').submit();
            } else if ($('.search-input').hasClass('open')) {
              $('.search-input').removeClass('open');
              $(document).off('mouseup', hide_search_bar);
            } else {
              $('.search-input').addClass('open').focus();
              $(document).on('mouseup', hide_search_bar);
            }
          });

          $('#menu-toggle').click(function(event) {
            $('#mobile-menu').slideToggle({
              duration: 550,
              easing: 'easeOutCubic'
            });
          });

          var close_the_menu = function() {
            if (window.innerWidth > 800 && $('#mobile-menu').is(':visible')) {
              $('#mobile-menu').slideUp({
                duration: 550,
                easing: 'easeOutCubic'
              });
            }
          };

          var adjustTriangle = function() {
            var w = $('.brand-wrap').innerWidth();
            $('.triangle').css({borderLeftWidth: w/2, borderRightWidth: w/2});
          };

          if ($('#sidebar-archives').length) {

            var toggle_sidebar_archive_view = function() {
              $('#sidebar-archives li:not(.year)').hide();

              $('#sidebar-archives li.year').each(function(index, el) {

                if ($(this).hasClass('active')) {
                  $(this).nextAll(':not(.year)').show();
                } else {
                  $(this).nextAll(':not(.year)').hide();
                }

              });

            };

            if ($('#sidebar-archives li.year.active').length === 0) {
              $('#sidebar-archives li.year:first-child').addClass('active');
            }

            toggle_sidebar_archive_view();

            $('#sidebar-archives li.year').click(function(event) {
              $(this).addClass('active');
              $(this).siblings('.year').removeClass('active');
              toggle_sidebar_archive_view();
            });
            
          }

          $(window).on('resize', close_the_menu);
          $(window).on('resize', adjustTriangle);


        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // Adjust the triangle
        var w = $('.brand-wrap').innerWidth();
        $('.triangle').css({borderLeftWidth: w/2, borderRightWidth: w/2});
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact_us': {
      init: function() {
        window.sei_contact_map_init = function() {
          map = new google.maps.Map(document.getElementById('sidebar-map'), {
            center: {lat: -34.397, lng: 150.644},
            zoom: 8,
            disableDefaultUI: true,
            zoomControl: true,
            scrollwheel: false
          });

          var address = "228 Airport Rd., Erwin, NC 28339";
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode( { 'address': address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              map.setCenter(results[0].geometry.location);
              var marker = new google.maps.Marker({
                  map: map,
                  position: results[0].geometry.location
              });
            } else {
              alert("Geocode was not successful for the following reason: " + status);
            }
          });
        };

        function addScript( url, callback ) {
            var script = document.createElement( 'script' );
            if( callback ) script.onload = callback;
            script.type = 'text/javascript';
            script.src = url;
            document.body.appendChild( script );
        }

        function loadMapsAPI() {
            addScript( 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwA2iLlnrFXZyhDyVXwXPFtIfY4O4U000&callback=window.sei_contact_map_init' );
        }

        window.onload = loadMapsAPI;

      },
      finalize: function() {
      }
    },
    'portfolio': {
      finalize: function() {
        $(function() {

          var prepare_mix = function(el) {
            el.mixItUp({
              selectors: {
                target: '.portfolio-image'
              },
              callbacks: {
                onMixLoad: function(state) {
                  window.dispatchEvent(new Event('resize'));
                }
              }
            });
          };

          $('.tabs').tabs({
            activate: function( event, ui ) {
              ui.oldPanel.mixItUp('destroy');
              prepare_mix(ui.newPanel);
              
              //setTimeout(function() { console.log($('#'+id).mixItUp({selectors: {target: '.portfolio-image'}})); }, 10);
            }
          });
          
          $('.gallery').magnificPopup({
            delegate: 'a:visible', // child items selector, by clicking on it popup will open
            type: 'image',
            image: {
              titleSrc: 'data-caption'
            },
            gallery: {
              enabled: true
            },
            zoom: {
              enabled: true, // By default it's false, so don't forget to enable it

              duration: 300, // duration of the effect, in milliseconds
              easing: 'ease-in-out', // CSS transition easing function

              // The "opener" function should return the element from which popup will be zoomed in
              // and to which popup will be scaled down
              // By defailt it looks for an image tag:
              opener: function(openerElement) {
                // openerElement is the element on which popup was initialized, in this case its <a> tag
                // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                return openerElement.is('img') ? openerElement : openerElement.find('img');
              }
            }
          });

          prepare_mix($('.mix-it-up:visible'));

        });
        
      }
    },
    'tips_considerations': {
      finalize: function() {
        $(function() {
          $('.tabs').tabs();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
